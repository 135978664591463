import React, { useState } from "react";
import { Link } from "gatsby";
import logo from "../img/logo.png";

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);

  return (
    <nav
      className="navbar has-background-black has-text-primary"
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item has-text-primary" title="Logo">
            <img src={logo} alt="NEBULA" />&nbsp;ИEBUL∆
          </Link>
          {/* Hamburger menu */}
          <button
            className={`navbar-burger burger has-text-primary ${isActive && "is-active"}`}
            aria-expanded={isActive}
            onClick={() => setIsActive(!isActive)}
            aria-label="Menu"
            title="Menu"
          >
            <span />
            <span />
            <span />
          </button>
        </div>
        <ul id="navMenu" className={` navbar-end has-text-centered navbar-menu has-background-black has-text-primary ${isActive && "is-active"}`}>
          <li className="navbar-item" style={{padding: "0px"}}>
            <Link className="navbar-item" to="/about">∆BOUT</Link>
          </li>
          <li className="navbar-item" style={{padding: "0px"}}>
            <Link className="navbar-item" to="/band">B∆ИD</Link>
          </li>
          <li className="navbar-item" style={{padding: "0px"}}>
            <Link className="navbar-item" to="/music">MUSIC</Link>
          </li>
          <li className="navbar-item" style={{padding: "0px"}}>
            <a className="navbar-item" href="https://www.facebook.com/nebuladeltait/events" target="_blank" rel="noreferrer">LIVES</a>
          </li>
          <li className="navbar-item" style={{padding: "0px"}}>
            <a className="navbar-item" href="https://www.youtube.com/channel/UCoNv8DAKt4OjiDUSxjLZoig" target="_blank" rel="noreferrer">VIDEOS</a>
          </li>
          <li className="navbar-item" style={{padding: "0px"}}>
            <a className="navbar-item" href="https://www.instagram.com/nebuladeltait/" target="_blank" rel="noreferrer">PHOTOS</a>
          </li>
          <li className="navbar-item" style={{padding: "0px"}}>
            <a className="navbar-item" href="https://www.facebook.com/nebuladeltait" target="_blank" rel="noreferrer">ИEWS</a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
