import * as React from "react";

import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import youtube from "../img/social/youtube.svg";
import spotify from "../img/social/spotify.svg";
import applemusic from "../img/social/applemusic.svg";

const Footer = () => {
  
    return (
      <footer className="footer content has-text-centered has-background-black">
        <div className="social">
          <a title="YouTube" href="https://www.youtube.com/channel/UCoNv8DAKt4OjiDUSxjLZoig" target="_blank" rel="noreferrer">
            <img
              className="fas fa-lg"
              src={youtube}
              alt="YouTube"
              style={{ width: "1em", height: "1em" }}
            />
          </a>
          <a title="Spotify" href="https://open.spotify.com/artist/3KdZzW82DUPvrHbC5IGE29?si=cJhXx9PmRqSpSC6B7vUN_A" target="_blank" rel="noreferrer">
            <img
              src={spotify}
              alt="Spotify"
              style={{ width: "1em", height: "1em" }}
            />
          </a>
          <a title="Apple Music" href="https://music.apple.com/it/artist/nebula/1452050587" target="_blank" rel="noreferrer">
            <img
              src={applemusic}
              alt="Apple Music"
              style={{ width: "1em", height: "1em" }}
            />
          </a>
          <a title="Instagram" href="https://www.instagram.com/nebuladeltait/" target="_blank" rel="noreferrer">
            <img
              src={instagram}
              alt="Instagram"
              style={{ width: "1em", height: "1em" }}
            />
          </a>
          <a title="Facebook" href="https://www.facebook.com/nebuladeltait" target="_blank" rel="noreferrer">
            <img
              src={facebook}
              alt="Facebook"
              style={{ width: "1em", height: "1em" }}
            />
          </a>
        </div>
        <div className="social">
          © 2018-2023 nebuladelta.it
        </div>
      </footer>
    );
};

export default Footer;
